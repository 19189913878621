import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { useMember } from '@src/services/member/MemberProvider';
import { isMobile } from '@src/utils/helpers/device';
import { GetDeviceInfoProps } from '@src/utils/hooks/api/types/getDeviceInfo';
import { ValidateDeviceSwapProps } from '@src/utils/hooks/api/types/validateDeviceSwap';
import DOMPurify from 'isomorphic-dompurify';

interface IChangeAccessBody {
    deviceInfo: GetDeviceInfoProps;
    validateDeviceSwap: ValidateDeviceSwapProps;
}

export const ChangeAccessBody = ({ deviceInfo, validateDeviceSwap }: IChangeAccessBody) => {
    const { t } = useTranslation();

    const {
        state: { accessType }
    } = useMember();

    const { deviceId, deviceDescription } = deviceInfo || {};

    const isCardType = accessType === 'card';

    if (!validateDeviceSwap) return null;

    if (!isCardType && deviceId) {
        deviceInfo.deviceDescription ||= t('phone');
    }

    if (isCardType) {
        return (
            <Text size={2} className="mb-xs">
                <span
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('change-access.card.body'))
                    }}
                />
            </Text>
        );
    }

    if (!isCardType && !deviceDescription && isMobile()) {
        return (
            <div>
                <Text size={2} className="mb-xs">
                    {t(
                        validateDeviceSwap.allowMshpDeviceSwap
                            ? 'access-change-legacy.no-device.body-app'
                            : 'change-access.no-device.body-app'
                    )}
                </Text>
                <Heading size={8} className="mt-xs mb-xxs">
                    {t('change-access.no-device.subtitle')}
                </Heading>
                <ol className="pl-m access-change-list text-regular-s">
                    <li className="list-decimal">{t('change-access.no-device.instruction-1')}</li>
                    <li className="list-decimal">{t('change-access.no-device.instruction-2')}</li>
                    <li className="list-decimal">{t('change-access.no-device.instruction-3')}</li>
                </ol>
            </div>
        );
    }

    if (!isCardType && !deviceDescription && !isMobile()) {
        return (
            <Text size={2} className="mb-xs">
                {t(
                    validateDeviceSwap.allowMshpDeviceSwap
                        ? 'access-change-legacy.no-device.body-mbf'
                        : 'access-change.no-device.body-mbf'
                )}
            </Text>
        );
    }

    return (
        <Text size={2} className="mb-xs">
            {t(
                validateDeviceSwap?.allowMshpDeviceSwap
                    ? 'access-change-legacy.device.body'
                    : 'change-change.device.body'
            )}
        </Text>
    );
};
