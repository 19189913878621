/* eslint-disable react/prop-types */
import React from 'react';
import * as Legacy from 'react-select';
import { cn } from '@basic-fit/design-system';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Icon } from '@src/components/Icons/Icons';

/**
 * Create custom theme for react-select
 * https://react-select.com/styles
 */
/**
 * @deprecated is no longer be used in the new Select components
 */
const defaultFont = {
    fontFamily: 'Heading Pro Treble, sans-serif',
    fontWeight: 700
};

/**
 * @deprecated is no longer be used in the new Select components
 */
const customStyles = {
    control: (base: any) => ({
        ...base,
        ...defaultFont,
        background: '#ECE9E8',
        borderRadius: 0,
        fontSize: '18px',
        lineHeight: '56px',
        paddingLeft: '16px'
    }),
    option: (base: any) => ({
        ...base,
        ...defaultFont,
        borderRadius: 0,
        fontSize: '18px',
        paddingLeft: '20px',
        lineHeight: '56px'
    }),
    valueContainer: (base: any) => ({
        ...base,
        padding: 0
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: '#FE7000'
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};

/**
 * @deprecated is no longer be used in the new Select components
 */
export const selectStylesWhite = {
    control: (base: any) => ({
        ...base,
        ...defaultFont,
        background: '#ffffff',
        borderRadius: 0,
        fontSize: '14px',
        lineHeight: '40px',
        paddingLeft: '16px'
    }),
    option: (base: any) => ({
        ...base,
        ...defaultFont,
        borderRadius: 0,
        fontSize: '14px',
        paddingLeft: '20px',
        lineHeight: '34px'
    }),
    valueContainer: (base: any) => ({
        ...base,
        padding: 0
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        visibility: 'hidden'
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: '#2D2D2D'
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    noOptionsMessage: (base: any) => ({
        ...base,
        ...defaultFont,
        fontSize: '14px'
    })
};

/**
 * @deprecated use the new Select components from this file
 */
export const SelectElement = <
    Option,
    IsMulti extends boolean = false,
    Group extends Legacy.GroupBase<Option> = Legacy.GroupBase<Option>
>(
    props: Legacy.Props<Option, IsMulti, Group>
) => {
    return (
        // this is the old Select component from react-select
        <Legacy.default
            {...props}
            styles={props.styles || customStyles}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#FE7000',
                    primary25: '#ECE9E8',
                    primary50: '#EFEFEF'
                }
            })}
            isSearchable={false}
        />
    );
};

const Select = SelectPrimitive.Root;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            'flex h-10 w-full items-center justify-between border bg-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 data-[placeholder]:text-grey-disabled p-6 pr-4',
            className
        )}
        {...props}
    >
        {children}
        <SelectPrimitive.Icon asChild>
            <Icon id="chevron-down" className="h-4 w-4 opacity-50" />
        </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollUpButton
        ref={ref}
        className={cn('flex cursor-default items-center justify-center py-1', className)}
        {...props}
    >
        <Icon id="chevron-up" className="h-4 w-4" />
    </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollDownButton
        ref={ref}
        className={cn('flex cursor-default items-center justify-center py-1', className)}
        {...props}
    >
        <Icon id="chevron-down" className="h-4 w-4" />
    </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                'relative z-50 max-h-96 min-w-[8rem] overflow-hidden border shadow-md py-1 bg-white rounded',
                position === 'popper' &&
                    'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
                className
            )}
            position={position}
            {...props}
        >
            <SelectScrollUpButton />
            <SelectPrimitive.Viewport
                className={cn(
                    position === 'popper' &&
                        'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
            <SelectScrollDownButton />
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn('py-1.5 px-2 text-sm font-semibold', className)}
        {...props}
    />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            'relative flex w-full cursor-default select-none items-center py-2 pr-2 pl-4 text-sm outline-none focus:bg-secondary-jersey-gray data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[state=checked]:bg-orange data-[state=checked]:text-white',
            className
        )}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"></span>

        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px', className)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
    Select,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton
};
