import { AUTO_CANCELLATION_COUNTRIES, GRACE_PERIOD_MONTHS } from '@src/services/utilities';
import { dateIsInPast, subtractPaymentInterval } from '@src/utils/helpers/date';
import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import i18next from 'i18next';
import { DateTime } from 'luxon';

import { Invoice, MemberDataProps } from '../../../types/member.types';

export const hasMembership = (member: any) => member?.membership || member?.membership !== 'none';

export const isEmployee = (member: any) => member?.membership?.display_name === 'EMPLOYEE';

export const hasCardNumber = (member: any) => {
    return member && member.card_number_s && member.card_number_s.length > 4;
};

export const getRenewalStartDate = (member: any) => {
    if (isContractEndInPast(member)) {
        return DateTime.now().toISO({ suppressMilliseconds: true, includeOffset: false });
    }

    return member.contract_end_date_g;
};

export const isContractEndInPast = (member: any) =>
    DateTime.fromISO(member.contract_end_date_g) < DateTime.now();

export const isAccessTypeQRCode = (member: any) => member?.accessType === 'qr';

export const isSecondary = (member: any) => !!member?.mainMemberId;

export const isContractEnded = (member: any) => {
    if (!member?.end_date_g) {
        return false;
    }

    const membershipEndDate = new Date(member.end_date_g);
    const now = new Date();
    return now > membershipEndDate;
};

export const isInGracePeriod = (member: any) => {
    if (!isContractEnded(member)) {
        return false;
    }

    const gracePeriodDurationInMonths = Number(GRACE_PERIOD_MONTHS) || 12;
    const endContractDate = DateTime.fromISO(member.end_date_g);
    const endGraceDate = endContractDate.plus({ months: gracePeriodDurationInMonths });
    const diff = DateTime.now().diff(endGraceDate, 'months');

    return diff.months < 0; // if diff is negative, the contract is in grace period
};

export const getMemberName = (member: any) => {
    const { first_name, middle_name, last_name } = member || {};
    if (middle_name) {
        return `${first_name} ${middle_name} ${last_name}`;
    }
    return `${first_name} ${last_name}`;
};

export const canChangeMembership = (member: any) => {
    return (
        (hasMembership(member) && !isEmployee(member) && !member.isOnlineOnly && !member.isAllIn) ||
        (!isEmployee(member) && member.home_club_s?.billingCountry?.toLowerCase() === 'germany')
    );
};

export const canRenewMembership = (member: any) => {
    return (
        member.peopleMembership?.allowCurrentContractRenewal &&
        member.payment_plan?.autocancel &&
        subtractPaymentInterval(
            member.peopleMembership?.peopleMembershipContractEndDate,
            member.country_g?.countryAutocancelRenewalPeriod
        ) <= DateTime.now() && // Returning false here in the date comparison
        member.membership_id_g === member.payment_plan?.membership_id
    );
};

export const isGerman = (member: any) => {
    const { billingCountry } = member?.home_club_s || {};
    return billingCountry?.toLowerCase() === 'germany' || false;
};

export const changeClubIsAllowed = (clubChangePossibleFrom?: string) =>
    clubChangePossibleFrom ? dateIsInPast(clubChangePossibleFrom) : true;

export const getLastInvoiceDate = (member: MemberDataProps, invoices: Array<Invoice>) => {
    const contractEndDate = DateTime.fromISO(member.contract_end_date_g);
    const isMemberInContract = contractEndDate > DateTime.now();
    if (isMemberInContract) {
        // If member still in a contract and on 4 weeks interval, last invoice date is 4 weeks before contract end date
        if (member.payment_plan.interval === PaymentInterval.FourWeeks) {
            return contractEndDate.minus({ weeks: 4 });
        }
        // If member still in a contract and on 52 weeks interval, last invoice date is 52 weeks before contract end date
        if (member.payment_plan.interval === PaymentInterval.FiftyTwoWeeks) {
            return contractEndDate.minus({ weeks: 52 });
        }
    }
    // If member is not bound by a contract, last invoice date is equal to the previous invoice date
    const lastInvoice = Array.isArray(invoices) && invoices.length ? invoices[0] : null;

    if (lastInvoice) {
        const lastInvoiceDate = DateTime.fromISO(lastInvoice.from);
        if (lastInvoiceDate.isValid) return lastInvoiceDate;
    }

    // We could not find any invoice, so we return the contract end date
    return contractEndDate;
};

export const hasAccessCard = (member: MemberDataProps) => {
    return (
        member.accessType?.toLowerCase() !== 'qr' &&
        member?.card_number_s &&
        !member.card_number_s?.toLowerCase().includes('v')
    );
};

export const isAutoCancellationCountry = (country: string): boolean => {
    return String(AUTO_CANCELLATION_COUNTRIES).includes(country.toLowerCase());
};

/**
 * Returns translated amount of weeks/years for a given payment interval
 * -> 4 weken
 * -> 52 weeks
 * @param interval
 */
export const getTranslatedPaymentInterval = (interval: PaymentInterval) => {
    switch (interval) {
        case PaymentInterval.FiftyTwoWeeks:
            return `52 ${i18next.t('264')}`;
        case PaymentInterval.OneMonth:
            return `1 ${i18next.t('368')}`;
        case PaymentInterval.OneWeek:
            return `1 ${i18next.t('371')}`;
        case PaymentInterval.TwoWeeks:
            return `2 ${i18next.t('264')}`;
        case PaymentInterval.TwoMonths:
            return `2 ${i18next.t('367')}`;
        case PaymentInterval.ThreeMonths:
        case PaymentInterval.Quarter:
            return `3 ${i18next.t('367')}`;
        case PaymentInterval.HalfYear:
            return `6 ${i18next.t('367')}`;
        case PaymentInterval.Year:
            return `1 ${i18next.t('369')}`;
        case PaymentInterval.TwoYears:
            return `2 ${i18next.t('370')}`;
        case PaymentInterval.ThreeYear:
            return `3 ${i18next.t('370')}`;
        case PaymentInterval.FourYears:
            return `4 ${i18next.t('370')}`;
        case PaymentInterval.FourWeeks:
        default:
            return `4 ${i18next.t('264')}`;
    }
};
