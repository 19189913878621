import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text } from '@basic-fit/design-system';
import { Modal } from '@src/components/Modals/Modal';

type FreezeExtraModalProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    onContinue: () => void;
};

export const FreezeExtraModal = ({
    showModal,
    setShowModal,
    onContinue
}: FreezeExtraModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={showModal} onModalClose={() => setShowModal(false)}>
            <div className="space-y-6">
                <div>
                    <Heading size={6} className="mb-4 uppercase">
                        {t('freeze.extra.modal.title')}
                    </Heading>
                    <Text size={1}>{t('freeze.extra.modal.description')}</Text>
                </div>
                <div>
                    <Button
                        fullwidth
                        type="button"
                        theme="default"
                        data-cy="are-you-sure__continue-button"
                        onClick={onContinue}
                    >
                        {t('freeze.extra.modal.confirm')}
                    </Button>
                </div>
                <div>
                    <Button
                        fullwidth
                        type="button"
                        theme="light"
                        className="text-regular-m"
                        data-cy="are-you-sure__back-button"
                        onClick={() => setShowModal(false)}
                    >
                        {t('close')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
