import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { Spinner } from '@src/components/Spinner/Spinner';
import { copyToClipboard } from '@src/services/utilities/clipboard.service';
import { canShare, share } from '@src/services/utilities/share.service';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import googleTagManagerPush from 'services/google-tag-manager-push';

interface AddFriendPopupProps {
    addFriend: boolean;
    setAddFriend: React.Dispatch<boolean>;
}

export const AddFriendModal = ({ addFriend, setAddFriend }: AddFriendPopupProps) => {
    const { t } = useTranslation();
    const {
        data: createFriendData,
        isLoading: isLinkLoading,
        isError: isLinkError
    } = useFetch<{ link: string }>('/friends/create-friend-invite-link');
    const {
        data: memberData,
        isLoading: isMemberLoading,
        isError: isMemberError
    } = useFetch<{ maxVisits: number }>('/friends/get-member');

    const [link, setLink] = useState<string>();
    const [maxVisits, setMaxVisits] = useState<number>(0);

    useEffect(() => {
        if (memberData) {
            setMaxVisits(memberData.maxVisits);
        }

        if (createFriendData) {
            const { hostname, port } = location;
            if (
                createFriendData.link.includes('http://') ||
                createFriendData.link.includes('https://')
            ) {
                return setLink(createFriendData.link);
            }
            setLink(
                `${hostname}${port ? `:${port}` : ''}/friends/register/${createFriendData.link}`
            );
        }
    }, [createFriendData, memberData]);

    const [linkCopied, setLinkCopied] = useState(false);
    const onCopyToClipboard = async () => {
        await copyToClipboard(link as string);
        googleTagManagerPush.addFriendLinkCopy();
        setLinkCopied(true);
    };

    const shareLink = async () => {
        if (canShare()) {
            share({
                title: t('230'),
                text: t('friends.invite.share.share.text'),
                url: link!
            });
        } else {
            await onCopyToClipboard();

            setLinkCopied(true);
        }
    };

    const shareLinkText = () => {
        if (canShare()) {
            return t('friends.invite.share.button');
        }
        return linkCopied ? t('237') : t('201');
    };

    return (
        <Modal isOpen={addFriend} onModalClose={() => setAddFriend(false)}>
            {/* add friend */}
            <Heading size={6} className="mbf-my-info__title">
                {t('198')}
            </Heading>
            {/* copy link... */}

            <Text size={2} className="mb-[30px] mt-2">
                {t('friends.invite.share.text')}
            </Text>

            {maxVisits > 0 && (
                <Text size={2} className="mb-[30px] mt-2">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('friends.invite.share.once.text').replace(
                                '%times%',
                                `${maxVisits.toString()}x`
                            )
                        }}
                    />
                </Text>
            )}

            <div className="cursor-pointer">
                <div className="relative">
                    <div
                        className={`
                            absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]
                            opacity-1 transition-opacity duration-300
                            ${isMemberLoading || isLinkLoading ? 'opacity-1' : 'opacity-0'}
                        `}
                    >
                        <Spinner size="l" />
                    </div>
                </div>
                {/* copy link */}
                <Button
                    data-cy="copy-to-clipboard"
                    disabled={isMemberLoading || isLinkLoading}
                    text={shareLinkText()}
                    onClick={shareLink}
                    className={`
                      mt-xs cursor-pointer disabled:cursor-not-allowed
                      ${linkCopied ? 'shadow-[inset_0_0_0_3px] shadow-mint' : ''}
                    `}
                />
            </div>

            {/* back */}
            <Button
                color="naked"
                onClick={() => setAddFriend(false)}
                text={t('162')}
                className="mt-xs"
            />

            {(isLinkError || isMemberError) && (
                <Text size={2} className="text-red mt-xs">
                    {`${t('238')} ${t('257')}`}
                </Text>
            )}
        </Modal>
    );
};
