import type { HTMLAttributes, PropsWithChildren } from 'react';
import React from 'react';
import { cn } from '@basic-fit/design-system';

type BoxProps = PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & {
        side?: 'right' | 'left' | 'top' | 'bottom';
        open: boolean;
        setOpen: (open: boolean) => void;
    }
>;

const openClassNames = {
    right: 'translate-x-0',
    left: 'translate-x-0',
    top: 'translate-y-0',
    bottom: 'translate-y-0'
};

const closeClassNames = {
    right: 'translate-x-full',
    left: '-translate-x-full',
    top: '-translate-y-full',
    bottom: 'translate-y-full'
};

const classNames = {
    right: 'inset-y-0 right-0',
    left: 'inset-y-0 left-0',
    top: 'inset-x-0 top-0',
    bottom: 'inset-x-0 bottom-0'
};

export const Drawer = ({ className = '', children, side = 'bottom', setOpen, open }: BoxProps) => {
    return (
        <div
            id={`dialog-${side}`}
            className={cn('relative z-10', className)}
            aria-labelledby="slide-over"
            role="dialog"
            aria-modal="true"
            onClick={() => setOpen(!open)}
        >
            {/* Overlay */}
            <div
                className={cn(
                    'fixed inset-0 bg-anthracite-74/75 duration-300 ease-ease-in-out transition-all',
                    {
                        'opacity-100 visible': open
                    },
                    { 'opacity-0 invisible': !open }
                )}
            ></div>
            {/* Drawer */}
            <div className={cn({ 'fixed inset-0 overflow-hidden': open })}>
                <div className="absolute inset-0 overflow-hidden">
                    <div
                        className={cn(
                            'pointer-events-none fixed',
                            classNames[side],
                            side === 'bottom' || side === 'top'
                                ? 'max-h-[80vh] h-auto w-full' // Restrict height for top/bottom
                                : 'max-w-[80vw] w-auto h-full' // Restrict width for left/right
                        )}
                    >
                        <div
                            className={cn(
                                'pointer-events-auto relative transform transition ease-ease-in-out duration-300',
                                { [closeClassNames[side]]: !open },
                                { [openClassNames[side]]: open },
                                'bg-white shadow-xl rounded-lg p-6'
                            )}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                        >
                            <div
                                className={cn(
                                    'flex flex-col overflow-y-auto',
                                    side === 'bottom' || side === 'top'
                                        ? 'max-h-[80vh]' // Ensure vertical scroll for top/bottom
                                        : 'h-full' // Ensure full height for left/right
                                )}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
