import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { AddOn } from '@src/components/Cards/AddOn';
import { Section } from '@src/components/Layout/Section';
import { Spinner } from '@src/components/Spinner/Spinner';
import { EventType } from '@src/pages/ChangeMembership/machines/changeMembershipMachine';
import { ChangeMembershipContext } from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';
import {
    extrasSelector,
    membershipSelector
} from '@src/pages/ChangeMembership/machines/changeMembershipSelectors';
import { useMember } from '@src/services/member/MemberProvider';
import {
    getArticleImage,
    getArticleIntervalText,
    handlePocDiscount
} from '@src/utils/helpers/articleHelpers';
import { isEqual } from '@src/utils/helpers/collections';
import { captureException } from '@src/utils/helpers/newRelic';
import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { Articles } from '@src/utils/hooks/api/useArticles';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { useSelector } from '@xstate/react';
import DOMPurify from 'isomorphic-dompurify';

import { isFreezeExtra } from '../utils';
import { FreezeExtraModal } from './FreezeAddonModal.modal';

export const Extras = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const [showFreezeModal, setShowFreezeModal] = React.useState(false);
    const [freezeItem, setFreezeItem] = React.useState<ArticleByPaymentSchedule | null>(null);

    const membershipService = useContext(ChangeMembershipContext);
    const selectedMembership = useSelector(membershipService.changeService, membershipSelector);
    const selectedExtras: ArticleByPaymentSchedule[] = useSelector(
        membershipService.changeService,
        extrasSelector
    );
    const { send } = membershipService.changeService;

    const { data: articles, isError: articlesFetchError } = useFetch<Articles>(
        '/memberships/get-articles'
    );

    const {
        data: availableArticles,
        isLoading: loadingAvailableArticles,
        isError: articlesByPaymentScheduleError
    } = useFetch<ArticleByPaymentSchedule[]>(
        selectedMembership
            ? `/memberships/get-articles-by-paymentschedule?paymentscheduleId=${selectedMembership?.id}&clubId=${member.home_club_id}`
            : null
    );

    const { pocDiscount } = useMBFFeatures();

    useEffect(() => {
        if (articlesFetchError) {
            captureException('failed to fetch articles', {
                component: 'Extras',
                selectedMembershipId: selectedMembership?.id ?? 'n/a',
                clubId: member.home_club_id,
                peopleId: member.id_g
            });
        }
    }, [articlesFetchError, selectedMembership, member]);

    useEffect(() => {
        if (articlesByPaymentScheduleError) {
            captureException('Error fetching articles by payment schedule', {
                component: 'Extras',
                selectedMembershipId: selectedMembership?.id ?? 'n/a',
                clubId: member.home_club_id,
                peopleId: member.id_g
            });
        }
    }, [articlesByPaymentScheduleError, selectedMembership, member]);

    const extendArticleDetails = (item: ArticleByPaymentSchedule) => {
        if (item.artc_short_description.includes('Yanga')) {
            return {
                description: t('membership.addon.yanga.description'),
                ...item
            };
        }

        if (item.artc_short_description.includes('Personal Online Coach')) {
            return {
                description: t('membership.addon.poc.description'),
                ...handlePocDiscount<ArticleByPaymentSchedule>(pocDiscount, item, item.artc_price)
            };
        }

        if (item.artc_short_description.includes('Personal Training Intro')) {
            return { description: t('membership.addon.pti.description'), ...item };
        }
        return { description: t(item.artc_long_description), ...item };
    };

    const extras =
        availableArticles
            ?.filter((item) => !item.artc_free && item.artc_price > 0)
            .sort((a, b) => b.artc_short_description.localeCompare(a.artc_short_description))
            .map((item) => extendArticleDetails(item)) || [];

    /**
     * Set extra(s) to active if they are bought in the current membership
     */
    const isMembershipExtra = (item: ArticleByPaymentSchedule) => {
        const extra = articles?.boughtArticles.find((article) =>
            article.articleShortDescription.includes(item.artc_short_description)
        );
        return !!extra;
    };

    useEffect(() => {
        if (!articles && !availableArticles) return;

        send({ type: 'SET.ARTICLES', value: availableArticles });
        const currentMembershipExtras = extras.filter(isMembershipExtra);
        for (const extra of currentMembershipExtras) {
            // ignore free articles
            if (extra.artc_free) continue;
            send({ type: EventType.ADD_EXTRA, value: extra });
            // set freeze item if it is a freeze extra
            if (isFreezeExtra(extra)) setFreezeItem(extra);
        }
    }, [articles, availableArticles]);

    useEffect(() => {
        if (!loadingAvailableArticles && availableArticles?.length === 0) {
            captureException(
                `No available articles found for membership ${selectedMembership?.id} and club ${member.home_club_id}`,
                {
                    selectedMembershipId: selectedMembership?.id ?? 'n/a',
                    component: 'Extras',
                    clubId: member.home_club_id
                }
            );
        }
    }, [loadingAvailableArticles, availableArticles]);

    /**
     * AddOn handlers
     */
    const isActiveExtra = (item: ArticleByPaymentSchedule) => {
        const match = selectedExtras.find((extra) => isEqual(extra, item));
        return !!match;
    };

    const handleExtra = (item: ArticleByPaymentSchedule) => {
        const type = isActiveExtra(item) ? EventType.REMOVE_EXTRA : EventType.ADD_EXTRA;
        send({ type, value: item });
    };

    if (!articles && !availableArticles) {
        return (
            <div className="flex justify-center py-l">
                <Spinner size="l" />
            </div>
        );
    }

    if (!loadingAvailableArticles && availableArticles?.length === 0) {
        return (
            <Section className="sm:p-0 p-m">
                <Box variant="full-grey" size="full">
                    <Text
                        size={1}
                        className="font-bold pb-l"
                        data-translation-key="change.membership.no_extras"
                    >
                        {t('change.membership.no_extras')}
                    </Text>
                    <Text
                        data-translation-key="change.membership.no_extras.contact_support"
                        size={1}
                        // Using dangerouslySetInnerHTML here to render the link to the support page
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                t('change.membership.no_extras.contact_support')
                            )
                        }}
                    />
                </Box>
            </Section>
        );
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-l mt-l">
            {freezeItem !== null && (
                <FreezeExtraModal
                    showModal={showFreezeModal}
                    setShowModal={setShowFreezeModal}
                    onContinue={() => {
                        if (freezeItem) {
                            send({ type: EventType.REMOVE_EXTRA, value: freezeItem });
                        }
                        setShowFreezeModal(false);
                    }}
                />
            )}

            {extras?.map((item) => (
                <div
                    key={item.artc_id}
                    className="flex flex-col items-center sm:p-0 md:items-start px-l"
                >
                    <AddOn
                        title={item.artc_short_description}
                        discountText={item.discountText}
                        originalPrice={item.originalPrice}
                        image={getArticleImage(item.artc_short_description)}
                        price={item.artc_price}
                        period={t(getArticleIntervalText(item, selectedMembership))}
                        onetime={!item.artc_periodical}
                        toggle={{
                            on: t('351'),
                            off: t('352'),
                            active: isActiveExtra(item),
                            changeOnClick: false
                        }}
                        button={{
                            text: isActiveExtra(item) ? t('membership.addon.remove') : t('77'),
                            variant: 'secondary'
                        }}
                        onClick={() => {
                            // show freeze modal if freeze extra is clicked
                            if (isFreezeExtra(item) && isActiveExtra(item)) {
                                return setShowFreezeModal(true);
                            }
                            handleExtra(item);
                        }}
                        details={{
                            closed: t('79'),
                            open: t('114'),
                            text: item?.description
                        }}
                    />
                </div>
            ))}
        </div>
    );
};
