'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, Icon, Text } from '@basic-fit/design-system';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { Modal } from '@src/components/Modals/Modal';
import { StockImage } from '@src/components/StockImage/StockImage';
import { Bold } from '@src/components/Text/Bold';
import { getDateWithMonthShortName } from '@src/utils/helpers/date';
import { isMobile } from '@src/utils/helpers/device';

interface FreezeDateProps {
    value: string;
    label: string;
    startDate: string;
    endDate: string;
    id: string;
}

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    context: 'confirm' | 'removed' | 'remove' | 'error' | 'debt' | 'stop' | 'stopped' | 'select';
    freezePeriod?: FreezeDateProps;
    onButtonClick?: () => void;
    modalsToTimeOut: string[];
    buttonDisabled?: boolean;
    freezeContractType?: string;
};

export const FreezeModal = ({
    isOpen,
    onClose,
    context,
    freezePeriod,
    onButtonClick,
    modalsToTimeOut,
    buttonDisabled
}: ModalProps) => {
    const { t } = useTranslation();

    const timeOutModalText = () => {
        const text = t(`freeze.modal.${context}.text`);
        if (context === 'confirm') {
            text.concat(' ' + t(`freeze.modal.${context}.text.bold`));
        }

        return text;
    };

    const modalTitle = () => {
        // Modals with no buttons
        if (modalsToTimeOut.includes(context)) {
            return t(`freeze.modal.${context}.title`);
        }
        // Debt modal
        if (context === 'debt') {
            return t('freeze.modal.not.available');
        }
        // Other modals
        return t(`freeze.modal.title.${context}`);
    };

    const modalText = () => {
        // Modals with no buttons
        if (modalsToTimeOut.includes(context)) {
            return timeOutModalText();
        }
        // Debt modal
        if (context === 'debt') {
            return t('freeze.modal.debt.message');
        }
        // Other modals
        return t(`freeze.modal.text.${context}`);
    };

    const modalButtonText = () => {
        // Debt modal
        if (context === 'debt') {
            return t('freeze.modal.debt.pay.button');
        }
        // Other modals
        return t(`freeze.modal.button.${context}`);
    };

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <Wrapper>
                <Heading
                    size={isMobile() ? 2 : 5}
                    className="mb-xs"
                    data-cy={`freeze-modal-${context}`}
                >
                    {modalTitle()}
                </Heading>

                {modalsToTimeOut.includes(context) && (
                    <div className="w-full flex justify-center">
                        <StockImage id="renewed" />
                    </div>
                )}
                <Text size={1} className="mb-s">
                    {modalText()}
                </Text>

                {(context === 'stop' || context === 'remove' || context === 'select') &&
                    freezePeriod && (
                        <div className="w-full border-solid border-b-[1px] border-grey mb-xxs py-1">
                            <Bold key={0}>
                                {getDateWithMonthShortName(freezePeriod.startDate)} -{' '}
                                {getDateWithMonthShortName(freezePeriod.endDate)}
                            </Bold>
                        </div>
                    )}

                {context === 'stop' && (
                    <Box gap={4} className="mt-s">
                        <Icon id="info-orange" />
                        <Text size={2}>{t('freeze.modal.info.stop')}</Text>
                    </Box>
                )}
                {!modalsToTimeOut.includes(context) && context !== 'error' && (
                    <>
                        <Button
                            className="mt-4"
                            fullwidth
                            onClick={onButtonClick}
                            data-cy={`freeze-modal-${context}-button`}
                            disabled={buttonDisabled}
                        >
                            {modalButtonText()}
                        </Button>
                        <Button
                            className="mt-4"
                            theme="light"
                            fullwidth
                            data-cy="freeze-modal-cancel-button"
                            onClick={() => onClose()}
                        >
                            {t('freeze.modal.remove.button.cancel')}
                        </Button>
                    </>
                )}
            </Wrapper>
        </Modal>
    );
};
