import React from 'react';
import { v4 as uuidv4 } from 'uuid';

type TableFlexProps = {
    className?: string;
    data: (string | JSX.Element)[][];
    size?: 's' | 'm';
    dataCy?: string;
};

export const TableFlex = ({ className = '', data, size, dataCy }: TableFlexProps) => {
    const getColumnWidth = (item: (string | JSX.Element)[]) => {
        switch (item.length) {
            case 3:
                return 'xs:w-4/12';
            case 4:
                return 'md:w-3/12';
            case 6:
                return 'md:w-2/12';
            default:
                return 'w-auto';
        }
    };

    return (
        <div
            className={`w-full divide-y divide-anthracite-74 ${className} ${
                size === 's' ? 'text-regular-s' : 'text-regular-s sm:text-regular-m'
            }`}
            data-cy={dataCy ?? ''}
        >
            {data.map((item) => (
                <div key={uuidv4()} className={`flex xs:flex-row flex-wrap py-xxs justify-between`}>
                    {item.map((value) => (
                        <div
                            key={uuidv4()}
                            className={`flex-auto last:truncate grow last:text-right ${getColumnWidth(
                                item
                            )}`}
                        >
                            {value}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
