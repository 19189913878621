'use client';
import React from 'react';
import { useState } from 'react';
import { DateRange, DayPicker, DayPickerRangeProps } from 'react-day-picker';
import { cn } from '@basic-fit/design-system';
import { DateTime, Interval } from 'luxon';

type DateRangePickerProps = Omit<
    DayPickerRangeProps,
    'mode' | 'selected' | 'onSelect' | 'components' | 'disabled' | 'month' | 'setMonth'
> & {
    handleSelect: (range: DateRange | undefined) => void;
    disabled: { from: Date; to: Date }[];
    onError?: (error: any) => void;
};

export function DateRangePicker({
    className,
    classNames,
    handleSelect,
    disabled,
    onError,
    ...props
}: DateRangePickerProps) {
    const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>(undefined);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>(undefined);
    const [displayMonth, setDisplayMonth] = useState<Date | undefined>(undefined);
    const MAX_ALLOWED_FREEZE_DAYS = 28;
    return (
        <DayPicker
            mode="range"
            className={cn('p-3', className)}
            classNames={{
                months: 'flex flex-col space-y-4 items-center sm:space-x-4 sm:space-y-0',
                month: 'space-y-4 w-fit',
                caption: 'flex justify-center pt-1 relative items-center',
                caption_label: 'text-lg font-bold',
                nav: 'space-x-1 flex items-center',
                nav_button: 'h-12 w-12 bg-transparent p-0 opacity-50 hover:opacity-100',
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                table: 'border-collapse space-y-2',
                head_row: 'flex',
                head_cell:
                    'text-anthracite/50 rounded-md w-10 xxs:w-12 md:w-14 font-medium text-[0.8rem]',
                row: 'flex w-full mt-2',
                cell: 'h-10 w-10 xxs:h-12 xxs:w-12 md:h-14 md:w-14 text-center text-base p-0 relative [&:has([aria-selected].day-range-start)]:rounded-l-full [&:has([aria-selected].day-range-end)]:rounded-r-full [&:has([aria-selected])]:bg-anthracite-74 first:[&:has([aria-selected])]:rounded-l last:[&:has([aria-selected])]:rounded-r focus-within:relative focus-within:z-20',
                day: 'h-10 w-10 xxs:h-12 xxs:w-12 md:h-14 md:w-14 p-0 font-medium aria-selected:opacity-100',
                day_range_start: 'rounded-full !bg-anthracite !text-white day-range-start',
                day_range_end: 'rounded-full !bg-anthracite !text-white day-range-end',
                day_selected: 'bg-anthracite-74 text-anthracite',
                day_outside:
                    'day-outside text-anthracite/50 aria-selected:bg-mint/50 aria-selected:text-anthracite/50',
                day_disabled: 'text-anthracite/50 opacity-50 line-through',
                day_range_middle: 'bg-anthracite-74 text-anthracite',
                day_hidden: 'invisible',
                ...classNames
            }}
            month={displayMonth}
            onMonthChange={(month) => {
                setDisplayMonth(month);
            }}
            components={{
                IconLeft: () => <span>{'<'}</span>,
                IconRight: () => <span>{'>'}</span>
            }}
            selected={{
                from: selectedStartDate,
                to: selectedEndDate
            }}
            fixedWeeks
            disabled={disabled}
            onSelect={(range: DateRange | undefined) => {
                try {
                    setSelectedStartDate(range?.from);
                    setSelectedEndDate(range?.to);

                    if (range?.from && range.to) {
                        for (const disabledInterval of disabled) {
                            if (
                                Interval.fromDateTimes(
                                    DateTime.fromJSDate(range.from),
                                    DateTime.fromJSDate(range.to)
                                ).overlaps(
                                    Interval.fromDateTimes(
                                        DateTime.fromJSDate(disabledInterval.from),
                                        DateTime.fromJSDate(disabledInterval.to)
                                    )
                                )
                            ) {
                                setSelectedStartDate(undefined);
                                setSelectedEndDate(undefined);

                                throw new Error('Selected range overlaps with disabled range');
                            }
                        }

                        const days = DateTime.fromISO(range.to.toISOString())
                            .endOf('day')
                            .diff(
                                DateTime.fromISO(range.from.toISOString()).startOf('day'),
                                'days'
                            ).days;

                        // if range is more than 4 weeks
                        if (days > MAX_ALLOWED_FREEZE_DAYS) {
                            setSelectedStartDate(undefined);
                            setSelectedEndDate(undefined);

                            throw new Error('Selected range is more than 4 weeks');
                        }
                    }

                    handleSelect(range);
                } catch (error) {
                    if (onError) {
                        onError(error);
                    }
                }
            }}
            {...props}
        />
    );
}
DateRangePicker.displayName = 'DateRangePicker';
