import React, { HTMLAttributes } from 'react';
import { Icon } from '@src/components/Icons/Icons';

interface IPrimaryButton extends HTMLAttributes<HTMLButtonElement> {
    text?: string;
    icon?: string;
    isChecked: boolean;
    showArrow?: boolean;
}

export const ButtonWithIcon = ({
    children,
    className = '',
    icon = 'date',
    isChecked = false,
    text,
    showArrow = false,
    ...rest
}: IPrimaryButton): JSX.Element => {
    return (
        <button
            className={`
            ${
                isChecked
                    ? `text-[white]
              bg-orange active:bg-dark-orange hover:bg-dark-orange disabled:bg-dark-orange`
                    : ''
            }
            ${
                !isChecked
                    ? `text-[black] bg-white active:bg-gray hover:bg-gray disabled:bg-gray`
                    : ''
            }
            flex items-center mb-[4px] w-full h-[56px] font-bold 
                  text-[1rem] text-left transition-all duration-300 relative font-heading-treble px-[30px]
                  before:content-[''] before:opacity-[0] before:transition-opacity before:duration-300
                  before:w-[calc(100%+6px)] before:h-[calc(100%+6px)] before:bg-primary-focus before:outline-button-focus
                  before:absolute before:left-[-3px] before:top-[-3px] focus:before:opacity-[1] z-[0]
                  ${className}
                `}
            {...rest}
        >
            {icon && icon !== 'none' && icon === 'date' && (
                <Icon
                    id={icon}
                    className={`text-[18px] mr-[20px] ${isChecked ? 'text-white' : 'text-orange'}`}
                />
            )}
            <span className="relative z-[1] ">{text ? text : children}</span>
            {showArrow && (
                <Icon
                    id="arrow-right"
                    className={`text-[18px] ml-auto ${isChecked ? 'text-white' : 'text-orange'}`}
                />
            )}
        </button>
    );
};
