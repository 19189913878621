import React, { HTMLAttributes, MouseEvent } from 'react';
import { Icon } from '@src/components/Icons/Icons';

interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    icon: React.ReactElement;
    title: string;
    disabled?: boolean;
    color?: 'white' | 'gray';
    subtitle?: string;
    action?: 'link' | 'download';
}

/**
 * @deprecated due to very specific logic. Use (custom) button instead.
 */
export const IconButton = ({
    className = '',
    onClick,
    color = 'white',
    icon,
    title,
    disabled = false,
    subtitle,
    action = 'link',
    ...rest
}: IconButtonProps): JSX.Element => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`
                flex 
                items-center
                w-full
                ${
                    color === 'gray' &&
                    'bg-jersey-grey hover:bg-jersey-grey/50 disabled:bg-jersey-grey/50'
                }
                ${color === 'white' && 'bg-white hover:bg-jersey-grey disabled:bg-jersey-grey'}
                transition-all duration-300
                p-xs
                border-4 border-jersey-grey
                ${className}`}
            {...rest}
        >
            <div className="text-[150%] leading-none">{icon}</div>

            <div className="flex-1 px-xxs">
                <div className={`font-bold font-heading-treble text-heading-xs`}>{title}</div>

                {subtitle !== '' && (
                    <div className={`font-heading-pro-double text-regular-s`}>{subtitle}</div>
                )}
            </div>
            {!disabled && (
                <div>
                    {action === 'download' ? <Icon id="download" /> : <Icon id="arrow-right" />}
                </div>
            )}
        </button>
    );
};
