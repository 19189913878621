import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Header } from '@src/components/Header/Header';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { StockImage } from '@src/components/StockImage/StockImage';
import { UpgradeBanner } from '@src/pages/Friends/components/UpgradeBanner';
import { CTA } from '@src/pages/Overview/components/Content/Components/CTA.overview';
import { OverviewFooter } from '@src/pages/Overview/components/Footer.overview';
import { useMember } from '@src/services/member/MemberProvider';
import { isMobile } from '@src/utils/helpers/device';

import { YourFriendList } from './components/FriendsList/FriendsList';

export const FriendsPage = () => {
    const { state: member } = useMember();
    const { is_friends_allowed: isFriendsAllowed, isEmployee } = member;
    const { t } = useTranslation();

    const newFriendsFlowInformationBlock = (
        <InformationBlock>
            <Text size={2}>{t('friends.headertext')}</Text>
        </InformationBlock>
    );

    return (
        <>
            {!isMobile() ? (
                <Header
                    title={t('33')}
                    text={t('195')}
                    image={<StockImage id="friends" className="h-full w-full !object-cover" />}
                />
            ) : (
                <Wrapper>
                    <Section>
                        <Heading size={2}>{t('friends.header')}</Heading>
                    </Section>
                </Wrapper>
            )}
            {member.newFriendsFlowEnabled && (
                <>
                    {isMobile() ? (
                        <Section className="mt-4">
                            <Wrapper>{newFriendsFlowInformationBlock}</Wrapper>
                        </Section>
                    ) : (
                        <div className="mt-[60px]">{newFriendsFlowInformationBlock}</div>
                    )}
                </>
            )}
            {/* INVITATION SENT SECTION && FRIENDS LIST */}
            {isFriendsAllowed && <YourFriendList />}
            {/* == friends not allowed == */}
            {!isFriendsAllowed && (
                // <Wrapper>
                <Section>
                    {/* <Wrapper> */}
                    <Box variant="grey">
                        {isEmployee ? <Text size={2}>{t('498')}</Text> : <UpgradeBanner />}
                    </Box>
                    {/* </Wrapper> */}
                </Section>
                // </Wrapper>
            )}
            {/* FAQ */}
            <Wrapper>
                <Section>
                    <FAQ category="friends" />
                </Section>
            </Wrapper>
            {/* CTA */}
            <Wrapper>
                <Section>
                    <CTA />
                </Section>
            </Wrapper>
            {/* FOOTER */}
            <Section>
                <OverviewFooter />
            </Section>
        </>
    );
};
