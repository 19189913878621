'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text } from '@basic-fit/design-system';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { Modal } from '@src/components/Modals/Modal';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { FreezeContractType } from '@src/services/freeze.service';
import { formatDate } from '@src/utils/helpers/date';
import { isMobile } from '@src/utils/helpers/device';
import { replacePlaceholder } from '@src/utils/helpers/stringHelpers';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    creditsRenewalDate?: string;
    buttonDisabled?: boolean;
    freezeContractType: FreezeContractType;
    creditAmount?: number;
    maxCredits: number;
};

export const FreezeInfoModal = ({
    isOpen,
    onClose,
    creditsRenewalDate,
    buttonDisabled,
    freezeContractType,
    creditAmount,
    maxCredits
}: ModalProps) => {
    const { t } = useTranslation();

    const basicfit = t('freeze.modal.text.credits.basicfit');
    const mcfit = t('freeze.modal.text.credits.mcfit');

    const infoModalText = () => {
        switch (freezeContractType) {
            case FreezeContractType.BasicFit:
                return basicfit;
            case FreezeContractType.McFit:
                // If the user has max 2 credits and a McFit membership, it means that they are after the Minimal Contract Period and the freeze logic is the same as for Basic-Fit
                return maxCredits === 2 ? basicfit : mcFitCreditsInfo(mcfit);
            case FreezeContractType.McFitAutocancel:
                return mcFitCreditsInfo(mcfit);
            default:
                return '';
        }
    };

    const mcFitCreditsInfo = (copy: string) => {
        if (creditAmount === undefined) return copy;
        return replacePlaceholder(copy, '%amount%', creditAmount.toString());
    };

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <Wrapper>
                <Heading size={isMobile() ? 2 : 5} className="mb-xs" data-cy="freeze-modal-info">
                    {t('freeze.modal.title.info')}
                </Heading>

                <Text size={1} className="mb-s">
                    {infoModalText()}
                </Text>

                {creditsRenewalDate &&
                    freezeContractType !== FreezeContractType.McFitAutocancel && (
                        <TableFlex
                            size="s"
                            className="border-solid border-b border-grey mb-xxs"
                            data={[
                                [
                                    <div key="0">{t('freeze.modal.enddate.info')} </div>,
                                    <div
                                        key="1"
                                        className="font-heading-treble font-bold"
                                    >{`${formatDate(creditsRenewalDate)}`}</div>
                                ]
                            ]}
                        />
                    )}

                <Button
                    className="mt-4"
                    fullwidth
                    onClick={onClose}
                    data-cy="freeze-modal-info-button"
                    disabled={buttonDisabled}
                >
                    {t('freeze.modal.button.info')}
                </Button>
            </Wrapper>
        </Modal>
    );
};
