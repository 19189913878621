import { getTranslatedPaymentInterval } from '@src/utils/helpers/memberHelpers';

export const getActiveMembership = (member: any) => {
    if (!member) {
        return false;
    }

    const contractEndDate = new Date(member.end_date_g);

    const activeMem = {
        contract_end_date_g: member.contract_end_date_g,
        term_fee_g: member.payment_plan ? member.payment_plan.term_fee : '',
        payment_plan_name_g: member.payment_plan_name_g,
        start_date_g: member.start_date_g,
        cancel_date_g: member.cancel_date_g,
        end_date_g: member.end_date_g,
        directdebit_interval: member.payment_plan ? member.payment_plan.directdebit_interval : '',
        display_name: '',
        display_duration: '',
        pay_interval: '',
        is_active: member.end_date_g === null || contractEndDate.getTime() >= Date.now()
    };

    /** display name */
    activeMem.display_name =
        member.membership_type_g?.toUpperCase() ||
        getMembershipName(activeMem.payment_plan_name_g || false);

    /** display duration */
    const durationInPaymentPlan =
        member.payment_plan && member.payment_plan.minimal_contract_period
            ? member.payment_plan.minimal_contract_period
            : null;
    if (!durationInPaymentPlan) {
        console.log('Duration error: No minimal_contract_period in payment plan!');
        activeMem.display_duration = 'N/A';
    } else {
        activeMem.display_duration = getTranslatedPaymentInterval(durationInPaymentPlan);
    }

    /** interval GM */
    activeMem.pay_interval =
        member && member.payment_plan && member.payment_plan.interval
            ? member.payment_plan.interval
            : false;

    return activeMem;
};

export const getMembershipName = (paymentSchedule: string): string => {
    if (!paymentSchedule) {
        console.log(`Invalid payment schedule: ${paymentSchedule}`);
        return 'N/A';
    }

    const scheduleMap = {
        employees: 'EMPLOYEE',
        easy: 'EASY',
        comfort: 'COMFORT',
        'home only': 'HOME ONLY',
        'premium 2': 'PREMIUM',
        'premium 1': 'PREMIUM',
        'basic 2': 'BASIC',
        'basic 1': 'BASIC',
        'basic 3': 'BASIC FLEX',
        'premium 3': 'PREMIUM FLEX',
        duo: 'DUO',
        smart: 'SMART',
        starterskit: 'STARTERSKIT',
        'stay-fit': 'STAY-FIT',
        'all-in': 'ALL-IN',
        'basic-fit': 'OTHER',
        basic: 'BASIC'
    };

    const pms = paymentSchedule.toLowerCase();
    const scheduleMapEntries = Object.entries(scheduleMap);
    // loop de loop, if any of the keys in the map exists in the pms, return the value =)
    for (let i = 0; i < scheduleMapEntries.length; i++) {
        const [key, value] = scheduleMapEntries[i];
        if (pms.includes(key)) {
            return value;
        }
    }

    return 'OTHER';
};

const sortMemberships = (a: any, b: any) => {
    return a.intervalPrice - b.intervalPrice;
};

export const getChangeMembershipData = (
    member: any,
    memberships: any,
    changeMembershipAllIn: any
) => {
    const fourWeeks: any = [];
    const _52Weeks: any = [];
    const unknown: any = [];
    for (const membership of memberships) {
        for (const paymentSchedule of membership.paymentschedule) {
            if (!changeMembershipAllIn && membership.membershipType === 'all-in') {
                continue;
            }

            // ignore current payment plan
            // TODO: when a paymentscheduleType is added, use this to make the filter
            if (member.payment_plan.id.toLowerCase() === paymentSchedule.id.toLowerCase()) {
                continue;
            }

            (paymentSchedule.paymentInterval === 'fourWeeks'
                ? fourWeeks
                : paymentSchedule.paymentInterval === '52weeks'
                ? _52Weeks
                : unknown
            ).push({
                ...paymentSchedule,
                name: getMembershipName(paymentSchedule.name).toLowerCase(),
                interval: paymentSchedule.paymentInterval,
                intervalPrice: paymentSchedule.termFee,
                membershipId: membership.id,
                membershipType: membership.membershipType
            });
        }
    }
    fourWeeks.sort(sortMemberships);
    _52Weeks.sort(sortMemberships);

    return { fourWeeks, _52Weeks, unknown };
};

export const isPremiumBenefitsActive = (member: any) => {
    return !!member?.membership_options_g?.LinkedServices?.includes('premiumBenefits');
};
