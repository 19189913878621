import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Icon, Text } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { ButtonWithIcon } from '@src/components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { DateRangePicker } from '@src/components/DateRangePicker/DateRangePicker';
import { Drawer } from '@src/components/Drawer/Drawer';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@src/components/Form/SelectElement/SelectElement';
import { Header } from '@src/components/Header/Header';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { Loader } from '@src/components/Loader/Loader';
import { Modal } from '@src/components/Modals/Modal';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { ReactComponent as HeaderImage } from '@src/pages/Membership/Membership/images/header.svg';
import {
    getFreezeContractType,
    isMcFitMemberWithFreeze,
    isMemberAllowedToFreeze
} from '@src/services/freeze.service';
import { useMember } from '@src/services/member/MemberProvider';
import { isEmpty } from '@src/utils/helpers/collections';
import {
    asGymManagerDateTimeString,
    getDateWithMonthShortName,
    isDateInPeriod
} from '@src/utils/helpers/date';
import { isMobile } from '@src/utils/helpers/device';
import { captureException } from '@src/utils/helpers/newRelic';
import { Articles } from '@src/utils/hooks/api/useArticles';
import { useBalanceSheet } from '@src/utils/hooks/api/useBalanceSheet';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useSWRConfig } from 'swr';

import { FreezeInfoModal } from './FreezeInfoModal';
import { FreezeModal } from './FreezeModal';

interface FreezeDateProps {
    value: string;
    label: string;
    startDate: string;
    endDate: string;
    id: string;
    countsTowardsLimit?: boolean;
}

type FreezeDatesResponse = {
    creditsRenewalDate: string;
    availableDates: FreezeDateProps[];
    frozenDates: FreezeDateProps[];
    usedFreezes: number;
    maxFreezes: number;
};

const MS_MODAL_TIMEOUT = 10_000;

export const FreezeMembershipPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state: member } = useMember();
    const { inDebt } = useBalanceSheet();
    const { mutate } = useSWRConfig();
    const [freezeDates, setFreezeDates] = useState<FreezeDateProps[]>();
    const [selectedFreezePeriod, setSelectedFreezePeriod] = useState<FreezeDateProps | null>();
    const [usedFreezes, setUsedFreezes] = useState<number>(0);
    const [frozenDates, setFrozenDates] = useState<FreezeDateProps[]>();
    const [disabledDates, setDisabledDates] = useState<{ from: Date; to: Date }[]>([]);
    const [freezeHistory, setFreezeHistory] = useState<FreezeDateProps[]>();
    const [deleteFreezePeriod, setDeleteFreezePeriod] = useState<FreezeDateProps>();
    const [activeFreeze, setActiveFreeze] = useState(false);
    const [showModal, setShowModal] = useState<
        'confirm' | 'removed' | 'remove' | 'error' | 'debt' | 'stop' | 'stopped' | 'select' | null
    >(null);
    const [showCreditsInfoModal, setShowCreditsInfoModal] = useState(false);
    const [creditsRenewalDate, setCreditsRenewalDate] = useState<string | undefined>();
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [addFreezeButtonDisabled, setAddFreezeButtonDisabled] = useState(true);
    const [isCalendarError, setIsCalendarError] = useState<'overlap' | 'max' | null>(null);
    const [disabled, setDisabled] = useState(false);
    const [maxFreezes, setMaxFreezes] = useState<number>(0);

    const modalsToTimeOut = ['confirm', 'removed', 'stopped'];

    const freezeContractType = getFreezeContractType(member);

    const { data: articles } = useFetch<Articles>('/memberships/get-articles');

    const {
        data: freezes,
        isLoading: isGetDatesLoading,
        isError: isGetDatesError
    } = useFetch<FreezeDatesResponse>(`/freeze/get-dates`);

    //Check if member is allowed to freeze
    useEffect(() => {
        if (!member || !articles) return;
        if (
            !isMemberAllowedToFreeze(member, articles?.boughtArticles) ||
            !articles?.boughtArticles
        ) {
            navigate('/membership');
            return;
        }
    }, [member, articles?.boughtArticles]);

    // Modal timeouts
    useEffect(() => {
        if (showModal && modalsToTimeOut.includes(showModal)) {
            const timeoutId = setTimeout(() => setShowModal(null), MS_MODAL_TIMEOUT);
            return () => clearTimeout(timeoutId);
        }
    }, [showModal]);

    // New relic error
    useEffect(() => {
        if (isGetDatesError) {
            captureException('failed to fetch freeze dates', {
                component: 'FreezeMembershipPage',
                peopleId: member.people_id_g
            });
        }
    }, [isGetDatesError]);

    // Get freeze dates
    useEffect(() => {
        if (!isEmpty(freezes) && !isGetDatesLoading) {
            getFreezeDates();
        }
    }, [freezes, isGetDatesLoading]);

    // Sort and set freeze dates
    const getFreezeDates = async () => {
        try {
            const now = new Date();
            const freezeHistory = freezes.frozenDates.filter(
                (date: FreezeDateProps) => date.countsTowardsLimit && new Date(date.endDate) < now
            );

            const freezeScheduled = freezes.frozenDates.filter(
                (date: FreezeDateProps) => date.countsTowardsLimit && new Date(date.endDate) > now
            );

            const activeFreeze = freezeScheduled.some(
                (date: FreezeDateProps) =>
                    new Date(date.startDate) < now && new Date(date.endDate) > now
            );

            const disabledDatesFromScheduled = freezes.frozenDates
                .filter((date: FreezeDateProps) => new Date(date.endDate) > now)
                .map((date: FreezeDateProps) => ({
                    from: new Date(date.startDate),
                    to: new Date(date.endDate)
                }));

            setCreditsRenewalDate(freezes.creditsRenewalDate);
            setMaxFreezes(freezes.maxFreezes);

            setActiveFreeze(activeFreeze);
            setFrozenDates(freezeScheduled);
            setFreezeHistory(freezeHistory);
            setUsedFreezes(freezes.usedFreezes);

            setDisabledDates(disabledDatesFromScheduled);

            const dates = freezes.availableDates.map((date) => ({
                value: date.startDate,
                label: `${new Date(date.startDate).toLocaleDateString('nl-NL')} ${t(
                    'freeze.page.till'
                )} ${new Date(date.endDate).toLocaleDateString('nl-NL')}`,
                startDate: date.startDate,
                endDate: date.endDate,
                id: ''
            }));

            setFreezeDates(dates);
        } catch (error) {
            setShowModal('error');
        }
    };

    // Handle McFit period selection
    const changeFreezePeriodHandler = (startDate: string) => {
        const option = freezeDates?.find((date) => date.startDate === startDate);

        if (option) {
            setSelectedFreezePeriod(option as FreezeDateProps);
        }
    };

    // Refresh data after mutations
    async function refreshData() {
        try {
            await mutate('/freeze/get-dates');
            await fetch('/member/update-member-info?force=true', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            await mutate('/member/get-member');
        } catch {
            // Intentionally left empty
        }
    }
    // Add new freeze period and refetch data
    const confirmFreezeHandler = async () => {
        if (!selectedFreezePeriod) return;

        if (isMcFitMemberWithFreeze(member) && inDebt) {
            return setShowModal('debt');
        }

        try {
            setDisabled(true);
            const response = await fetch('/freeze/create-freeze', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    startDate: asGymManagerDateTimeString(
                        new Date(selectedFreezePeriod?.startDate)
                    ),
                    endDate: asGymManagerDateTimeString(new Date(selectedFreezePeriod?.endDate))
                })
            });

            if (!response.ok) throw new Error('Error creating freeze period');

            await refreshData();
            setShowModal('confirm');
            setIsCalendarOpen(false);
            setSelectedFreezePeriod(null);
            setAddFreezeButtonDisabled(true);
        } catch (error) {
            setShowModal('error');
        } finally {
            setDisabled(false);
        }
    };

    // Handle remove or stop freeze period
    const removeFreezePeriodHandler = (key: number) => {
        if (!frozenDates) {
            console.error('No frozen dates available');
            return;
        }
        if (isDateInPeriod(frozenDates?.[key]?.startDate, frozenDates?.[key]?.endDate)) {
            setDeleteFreezePeriod(frozenDates[key]);
            setShowModal('stop');
            return;
        }
        if (frozenDates?.[key]?.id) {
            setDeleteFreezePeriod(frozenDates[key]);
            setShowModal('remove');
            return;
        }
    };

    // Remove freeze period
    const removeFreezePeriod = async () => {
        if (!deleteFreezePeriod) return;
        setDisabled(true);

        try {
            const response = await fetch(
                `/freeze/delete-freeze?freezeId=${deleteFreezePeriod.id}`,
                {
                    method: 'DELETE'
                }
            );

            if (!response.ok) throw new Error('Error deleting freeze period');

            if (showModal === 'remove') setShowModal('removed');
            if (showModal === 'stop') setShowModal('stopped');

            await refreshData();
        } catch (error) {
            setShowModal('error');
        } finally {
            setDisabled(false);
        }
    };

    // Get shceduled freeze rows and apply styling to active freeze period
    const getScheduledFreezePeriodRow = (startDate: string, endDate: string) => {
        if (isDateInPeriod(startDate, endDate)) {
            return (
                <div className="border-l-2 border-orange pl-2">
                    {' '}
                    <Bold>
                        {getDateWithMonthShortName(startDate)} -{' '}
                        {getDateWithMonthShortName(endDate)}
                    </Bold>
                </div>
            );
        }
        return `${getDateWithMonthShortName(startDate)} - ${getDateWithMonthShortName(endDate)}`;
    };

    // Handle Basic-Fit period selection in calendar
    const handleCalendarSelection = (range: DateRange | undefined) => {
        try {
            if (!range) {
                setSelectedFreezePeriod(null);
                return;
            }
            const startDate = range.from;
            const endDate = range.to;
            if (!startDate || !endDate) return;
            setSelectedFreezePeriod({
                value: startDate.toISOString(),
                label: `${startDate.toLocaleDateString('nl-NL')} ${t(
                    'freeze.page.till'
                )} ${endDate.toLocaleDateString('nl-NL')}`,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                id: ''
            });
            setAddFreezeButtonDisabled(false);
        } catch (error) {
            console.error('Error selecting freeze period', error);
        }
    };

    // Handle modal button click
    const handleModalButtonClick = () => {
        switch (showModal) {
            case 'remove':
                removeFreezePeriod();
                break;
            case 'debt':
                navigate('/payments');
                break;
            case 'stop':
                removeFreezePeriod();
                break;
            case 'select':
                confirmFreezeHandler();
                break;
            default:
                setShowModal(null);
        }
    };

    // Show error message if freeze period overlaps or is more than 4 weeks
    const handleCalendarError = (error: Error) => {
        if (error.message.includes('overlaps')) {
            setIsCalendarError('overlap');
        }
        if (error.message.includes('4 weeks')) {
            setIsCalendarError('max');
        }
    };

    // Show frozen information block if freeze period is active
    const FrozenInformationBlock = () => (
        <InformationBlock>
            <Text size={2}>{t('freeze.infoblock.text')}</Text>
        </InformationBlock>
    );

    // Basic-Fit Freeze Calendar
    const Calendar = () => (
        <>
            <Wrapper>
                <Heading size={isMobile() ? 2 : 6} className="mb-xs">
                    {t('freeze.calendar.title')}
                </Heading>
                <Text size={2} className="mb-s">
                    {t('freeze.calendar.text')}
                </Text>
                <DateRangePicker
                    fromDate={new Date(freezeDates?.at(0)?.startDate ?? '')}
                    toDate={new Date(freezeDates?.at(-1)?.endDate ?? '')}
                    handleSelect={(range) => handleCalendarSelection(range)}
                    disabled={disabledDates}
                    onError={(error) => {
                        handleCalendarError(error);
                    }}
                    className="mb-s"
                />
                <Button
                    onClick={() => setShowModal('select')}
                    disabled={addFreezeButtonDisabled}
                    loading={isGetDatesLoading}
                    className="w-full"
                >
                    {t('freeze.calendar.button')}
                </Button>
            </Wrapper>
            <Modal isOpen={isCalendarError !== null} onModalClose={() => setIsCalendarError(null)}>
                <Wrapper>
                    <Heading size={5} className="mb-xs">
                        {t(`freeze.calendar.${isCalendarError}.error.title`)}
                    </Heading>
                    <Text size={2} className="mb-s">
                        {t(`freeze.calendar.${isCalendarError}.error.text`)}
                    </Text>
                </Wrapper>
            </Modal>
        </>
    );

    // Loading state
    if (isGetDatesLoading) {
        return (
            <Section className="flex flex-col items-center justify-center">
                <Loader />
            </Section>
        );
    }
    return (
        <>
            {showModal !== null && (
                <FreezeModal
                    isOpen={showModal !== null}
                    onClose={() => setShowModal(null)}
                    freezePeriod={
                        showModal === 'select' && selectedFreezePeriod
                            ? selectedFreezePeriod
                            : deleteFreezePeriod
                    }
                    onButtonClick={handleModalButtonClick}
                    context={showModal}
                    modalsToTimeOut={modalsToTimeOut}
                    buttonDisabled={disabled}
                />
            )}
            {!isMobile() ? (
                <>
                    <Header
                        title={t('freeze.page.title')}
                        image={
                            <div className="absolute top-0 right-0">
                                <HeaderImage />
                            </div>
                        }
                    />
                    {activeFreeze && <div className="mt-xs">{FrozenInformationBlock()}</div>}
                </>
            ) : (
                <Wrapper>
                    {activeFreeze && (
                        <Section>
                            <Wrapper>{FrozenInformationBlock()}</Wrapper>
                        </Section>
                    )}
                    <Section>
                        <Heading size={2}>{t('freeze.page.title')}</Heading>
                    </Section>
                </Wrapper>
            )}
            <Wrapper>
                <Section>
                    <Text data-cy="freeze-header-text" size={2}>
                        {t(
                            `freeze.page.headertext${
                                isMcFitMemberWithFreeze(member) ? '.mcfit' : ''
                            }`
                        )}
                    </Text>
                </Section>
            </Wrapper>

            {/* Freeze History */}
            {freezeHistory && freezeHistory.length > 0 && (
                <Wrapper data-cy="freeze-header-history">
                    <Section>
                        <Heading size={5}>{t('freeze.page.history.title')}</Heading>

                        <TableFlex
                            size="s"
                            data-cy="freeze-history-table"
                            className="border-solid border-b border-grey"
                            data={freezeHistory.map((date) => [
                                `${getDateWithMonthShortName(
                                    date.startDate
                                )} - ${getDateWithMonthShortName(date.endDate)}`,
                                ''
                            ])}
                        />
                    </Section>
                </Wrapper>
            )}

            {/* Freeze scheduled periods */}
            {frozenDates && frozenDates.length > 0 && (
                <Wrapper data-cy="freeze-header-scheduled">
                    <Section>
                        <Heading size={5}>{t('freeze.page.scheduled.periods')}</Heading>
                        <TableFlex
                            size="s"
                            data-cy="freeze-scheduled-table"
                            className="border-solid border-b-[1px] border-grey"
                            data={frozenDates.map((date, index) => [
                                getScheduledFreezePeriodRow(date.startDate, date.endDate),

                                <DeleteFreezeButton
                                    key={date.id}
                                    onClick={() => removeFreezePeriodHandler(index)}
                                />
                            ])}
                        />
                        <ButtonLink to="/payments">
                            {t('freeze.page.scheduled.nextpaymentdate')}
                        </ButtonLink>
                    </Section>
                </Wrapper>
            )}

            {/* Freeze Credits Info Modal */}
            {showCreditsInfoModal && (
                <FreezeInfoModal
                    isOpen={showCreditsInfoModal}
                    onClose={() => setShowCreditsInfoModal(false)}
                    creditsRenewalDate={creditsRenewalDate}
                    buttonDisabled={disabled}
                    freezeContractType={freezeContractType}
                    creditAmount={usedFreezes !== undefined ? maxFreezes - usedFreezes : 0}
                    maxCredits={maxFreezes}
                />
            )}

            {/* Freeze Credits */}
            <Wrapper>
                <Section>
                    <Heading size={5}>{t('freeze.page.credits.title')}</Heading>
                    <TableFlex
                        size="s"
                        className="border-solid border-b-[1px] border-grey"
                        data={[
                            [
                                <div key="0">
                                    {t('freeze.page.schedule.freezeleft.text')}{' '}
                                    <Icon
                                        id={'question'}
                                        onClick={() =>
                                            setShowCreditsInfoModal(!showCreditsInfoModal)
                                        }
                                        className="px-[4px]"
                                    />
                                </div>,
                                <div key="1" className="font-heading-treble font-bold">{`${
                                    maxFreezes - usedFreezes
                                } ${t(
                                    'freeze.page.schedule.freezeleft.outof'
                                )} ${maxFreezes}`}</div>
                            ]
                        ]}
                    />
                    {/* Freeze Schedule Basic-Fit */}
                    {!isMcFitMemberWithFreeze(member) && !(maxFreezes - usedFreezes < 1) && (
                        <ButtonWithIcon
                            icon="date"
                            onClick={() =>
                                inDebt ? setShowModal('debt') : setIsCalendarOpen(!isCalendarOpen)
                            }
                            isChecked={false}
                            showArrow={true}
                            className="border-solid border-b-[1px] border-grey mb-s mt-xxs h-10 !px-1"
                            data-cy="freeze-schedule-button"
                        >
                            {t('freeze.page.select.button')}
                        </ButtonWithIcon>
                    )}
                </Section>
            </Wrapper>
            {/* Freeze FAQ [Basic-Fit] */}
            {!isMcFitMemberWithFreeze(member) && (
                <Section>
                    <FAQ category="freeze" />
                </Section>
            )}
            {/* Freeze Calendar [Basic-Fit] */}
            {!isMcFitMemberWithFreeze(member) &&
                (isMobile() ? (
                    <Drawer
                        side={'bottom'}
                        open={isCalendarOpen}
                        setOpen={() => {
                            setIsCalendarOpen(!isCalendarOpen);
                        }}
                    >
                        {Calendar()}
                    </Drawer>
                ) : (
                    <Modal
                        isOpen={isCalendarOpen}
                        onModalClose={() => setIsCalendarOpen(!isCalendarOpen)}
                    >
                        {Calendar()}
                    </Modal>
                ))}
            {/* Freeze Schedule [McFit] */}
            {isMcFitMemberWithFreeze(member) && (
                <Wrapper data-cy="freeze-header-scheduled">
                    <Section>
                        <Heading size={5} className="mb-2">
                            {t('freeze.page.schedule.title')}
                        </Heading>
                        <div>
                            <Text size={2}>
                                {usedFreezes === maxFreezes
                                    ? t('freeze.page.schedule.nofreezeleft')
                                    : t('freeze.page.schedule.text')}
                            </Text>
                        </div>

                        {usedFreezes < maxFreezes && (
                            <>
                                <div className="mt-4" data-cy="freeze-select-container">
                                    <Select
                                        onValueChange={(value) => changeFreezePeriodHandler(value)}
                                        disabled={isGetDatesLoading}
                                        data-cy="freeze-select-dropdown"
                                    >
                                        <SelectTrigger className="w-full">
                                            <SelectValue
                                                placeholder={t('freeze.page.select.placeholder')}
                                            />
                                        </SelectTrigger>

                                        <SelectContent>
                                            {freezeDates?.map((date) => (
                                                <SelectItem
                                                    key={`${date.startDate}-${date.endDate}`}
                                                    value={date.startDate}
                                                >
                                                    {date.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                <Button
                                    className="mt-4"
                                    onClick={confirmFreezeHandler}
                                    fullwidth
                                    data-cy="freeze-confirm-button"
                                    loading={isGetDatesLoading}
                                    disabled={disabled}
                                >
                                    {t('freeze.page.confirm.button')}
                                </Button>
                                <Button
                                    className="mt-4"
                                    onClick={() => navigate('/membership')}
                                    theme="light"
                                    fullwidth
                                    data-cy="freeze-back-button"
                                    loading={isGetDatesLoading}
                                    disabled={disabled}
                                >
                                    {t('freeze.page.cancel.button')}
                                </Button>
                            </>
                        )}
                    </Section>
                </Wrapper>
            )}
        </>
    );
};

function DeleteFreezeButton({ onClick }: { onClick: () => void }) {
    return (
        <button onClick={onClick} type="button">
            <Icon
                data-cy="freeze-scheduled-remove"
                className="text-[18px] text-primary"
                id={'bin'}
            />
        </button>
    );
}
