import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Icon, Text } from '@basic-fit/design-system';
import { IconButton } from '@src/components/Buttons/IconButton';
import { Section } from '@src/components/Layout/Section';
import { redirect } from '@src/utils/helpers/redirect';

export const FreezeMembershipComponent = () => {
    const { t } = useTranslation();

    return (
        <Section className="box box--white md:mx-xxl  px-l pb-l md:px-xxl md:pb-xxl bg-white">
            <Heading size={5} className="mb-4">
                {t('freeze.component.title')}
            </Heading>
            <Text size={2}>{t('freeze.component.text')}</Text>
            <IconButton
                className="mt-4"
                icon={<Icon id="freeze" />}
                data-cy="freeze-membership-button"
                title={t('freeze.component.button')}
                onClick={() => redirect('/membership/freeze')}
            />
        </Section>
    );
};
