import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import { BoughtArticles } from '@src/utils/hooks/api/useArticles';

import { MemberDataProps } from 'types/member.types';

export const isMemberAllowedToFreeze = (
    member: MemberDataProps,
    boughtArticles: BoughtArticles[]
) => {
    if (isMcFitMemberWithFreeze(member) || isFreezeIncludedInMembership(boughtArticles)) {
        return true;
    }

    return false;
};

export const isMcFitMemberWithFreeze = (member: any) => {
    return member?.membership_options_g?.LinkedServices?.includes('freezeAllowed');
};

export const getFreezeArticle = (articles: BoughtArticles[]) => {
    const freezeAddon =
        articles && articles?.find((article) => article.articleDescription.includes('Freeze'));
    return freezeAddon;
};

const isFreezeIncludedInMembership = (boughtArticles: BoughtArticles[]) => {
    const hasFreezeArticle = boughtArticles && getFreezeArticle(boughtArticles) ? true : false;
    return hasFreezeArticle;
};

export const getFreezeContractType = (member: MemberDataProps) => {
    if (isMcFitMemberWithFreeze(member)) {
        if (member.payment_plan.autocancel) {
            return FreezeContractType.McFitAutocancel;
        }
        return FreezeContractType.McFit;
    }
    return FreezeContractType.BasicFit;
};

export enum FreezeContractType {
    BasicFit = 'basicfit',
    McFit = 'mcfit',
    McFitAutocancel = 'mcfit-autocancel'
}

export const getFreezePeriodsNumberPerContract = (member: MemberDataProps) => {
    if (isMcFitMemberWithFreeze(member)) {
        const contractPeriod = member.payment_plan.minimal_contract_period as PaymentInterval;

        switch (contractPeriod) {
            case PaymentInterval.Year:
                return 6;
            case PaymentInterval.FiftyTwoWeeks:
                return 6;
            case PaymentInterval.HalfYear:
                return 3;
            case PaymentInterval.ThreeMonths:
                return 3;
            case PaymentInterval.OneMonth:
                return 3;
            default:
                return 0;
        }
    } else {
        return 2;
    }
};
